import  AuthProviderLocal  from 'TemplateConstants/authorization/AuthProvider'
import appConfig from 'app-config-global'
import apiConfig from '../../Api/api-config'

export class BaseController {
  readonly appConfig: object
  readonly apiHost: string
  // readonly apiPath: string
  readonly apiPathDigitalId: string
  private readonly fetchController: Record<string, any>
  private _abortController: AbortController
  readonly configKey: keyof typeof apiConfig

  constructor () {

    var isGlobalEnabled = localStorage.getItem('isGlobalEnabled') === 'true' ?? false;
    this.configKey = (isGlobalEnabled ? 'global': 'regional') as keyof typeof apiConfig;

    this.appConfig = appConfig
    this.apiHost = apiConfig[this.configKey].apiHost 
    this.apiPathDigitalId = apiConfig[this.configKey].paths.apiPathDigitalId
    this.fetchController = {}
    this._abortController = new AbortController()
  }
  
  async getHeaders (method: string, file?: boolean, fileName?: any, contentType?: string): Promise<Headers> 
  {
    let token = await AuthProviderLocal.getToken()
    
    const headers: Headers = new Headers({
      Authorization: `Bearer ${token}`
    })
    if (file) {
      headers.append('Content-Type', contentType || 'binary/octet-stream')
      if (!contentType) {
        headers.append('Content-Filename', fileName)
      }
    } else {
      headers.append('Content-Type', contentType || 'application/json')
    }

    return headers
  }

  async useFetch (method: string, fetchKey: string, url: string, body?: any, file?: any, fileName?: string, contentType?: string): Promise<any> {
    if (fetchKey && this.fetchController[fetchKey]) {
      this.fetchController[fetchKey].abort()
    }

    this.fetchController[fetchKey] = new AbortController()
    const { signal } = this.fetchController[fetchKey]

    const options: any = {
      method: method,
      headers: await this.getHeaders(method, file, fileName, contentType),
      signal
    }
    if (body) {
      options.body = file ? body : JSON.stringify(body)
    }

    const response = await fetch(url, options)

    // clear out controller
    this.fetchController[fetchKey] = null

    return response
  }

  async useFetchNoAbort(
    method: string,
    url: string,
    body: any = null,
    overrideHeaders: Record<string, string> = {},
    file: boolean = false,
    fileName?: string,
    contentType?: string
  ): Promise<any> {
  
    const options: any = {
      method: method,
      headers: await this.getHeaders(method, file, fileName, contentType)
    };
    if (body !== null) {
      options.body = file ? body : JSON.stringify(body);
    }
  
    const response = await fetch(url, options);
  
    return response;
  }
}

import { appInitialized } from '@mit/aws-react';
import { TravelController } from 'Api/controller'
import { appController } from 'Api/controller/AppController'
import { useEffect, useState } from 'react'
import { useStoreActions, store } from 'Store'
import * as globalStore from "Store/models/Global";

const travelController = new TravelController()

interface UseAppInitializationReturn {
	initializeApp: () => Promise<void>;
	initializeApi: boolean;
}

export const useAppInitializer = (): UseAppInitializationReturn => {
	const [initializeApi, setInitializeApi] = useState(false)
	useEffect(() => {
		appInitialized({
			isLoading: true,
			hasAccess: false,
			hasError: false,
		});
	}, []);

	const initializeApp = async (): Promise<void> => {
		appController.getTexts().then((res) => {
			store.getActions().GlobalModel.setTexts(res);
		})

		await travelController
			.getRoles()
			.then((res) => {
				if (!res.admin && !res.superAdmin && !res.manageHighRisk) {
					appInitialized({
						isLoading: false,
						hasAccess: false,
						hasError: false,
					});
					setInitializeApi(true)
				} else {
					store.getActions().GlobalModel.setRoles(res)
					travelController
						.getPrograms()
						.then((response) => {
							if (response.status !== 403) {
								appInitialized({
									isLoading: false,
									hasAccess: true,
									hasError: false,
								});
							} else {
								appInitialized({
									isLoading: false,
									hasAccess: true,
									hasError: true,
								});
							}
						})
						.catch(() => {
							appInitialized({
								isLoading: false,
								hasAccess: true,
								hasError: true,
							});
						})
					// .finally(() => {
					// 	appInitialized({
					// 		isLoading: false,
					// 		hasAccess: res.admin || res.superAdmin || res.manageHighRisk,
					// 		hasError: false,
					// 	  });
					// 	  setInitializeApi(true)
					// })

					travelController.getCountries().then((response) => {
						if (response.countries) store.getActions().GlobalModel.setCountries(response.countries)
					})

					travelController.getDepartments().then((response) => {
						if (response.departments) store.getActions().GlobalModel.setDepartments(response.departments)
					})
				}
			})
			.catch(() => {
				appInitialized({
					isLoading: false,
					hasAccess: false,
					hasError: true,
				});
			})
			setInitializeApi(true)
	};
	return { initializeApi, initializeApp }
};
import {Text} from 'Types/Text'
import {BaseController} from './BaseController'

class AppController extends BaseController {

  readonly getTextsUrl: string

  constructor () {
    super()
    this.getTextsUrl = `${this.apiHost}/text-v1/texts?appId=travel&language=en`
  }

  async getTexts (): Promise<Record<string, string>> {
    const response = await this.useFetch('GET', 'lookups', this.getTextsUrl)

    if (response.status === 200) {
        const data: any = await response.json()
      return data.reduce((acc: Record<string, string>, item: Text) => {
          return {...acc, [item.textId ?? '']: item.text}
        }, {})
    } else {
        const data = await response.json()
        return await Promise.reject(data.message)
    }
  }
}

const appController = new AppController()
export { appController }

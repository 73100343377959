import {GlobalModel, IGlobalModel} from './Global'
import {ITripModel, TripModel} from './Trip'
import {ITemplateModel, TemplateModel} from 'TemplateConstants/store/model'

export interface Model extends ITemplateModel {
  GlobalModel: IGlobalModel
  TripModel: ITripModel
}

export const model: Model = {
  ...TemplateModel,
  GlobalModel,
  TripModel
}

import { AuthProvider } from '@mit/aws-react'
import appConfig from "../../app-config-global";

class AuthProviderLocal {

  getToken = async (): Promise<any> => {
    const authorizationToken = await new AuthProvider(Object.assign(appConfig)).getToken()
    return authorizationToken;
  }

  signOut = async (): Promise<any> => {
    console.log('AuthProviderLocal.logout()')
    await new AuthProvider(Object.assign(appConfig)).signOut()
  }
  
}

const authProviderLocal = new AuthProviderLocal()
export default authProviderLocal
import {BaseController} from './BaseController'

import {Profile} from 'TemplateConstants/types'

export class ProfileController extends BaseController {
	async getProfile(): Promise<Profile> {
		const response = await this.useFetch('GET', 'profile', `${this.apiHost}/${this.apiPathDigitalId}/profile`)

		if (response.status === 200) {
			try {
        return await response.json()
			} catch (error) {
				return {
					department: '',
					display_name: '',
					email: '',
					first_name: '',
					initials: '',
					kerberos: '',
					kerberos_id: '',
					krb_name: '',
					last_name: '',
					mit_id: '',
					mitid: '',
					office_location: '',
					phone_number: '',
					source: ''
				}
			}
		} else {
			const text = await response.text()
			return await Promise.reject(text)
		}
	}

	// In ProfileController class
	async getProfilePicture(): Promise<Blob> {
		const response = await this.useFetch('GET', 'picture', `${this.apiHost}/${this.apiPathDigitalId}/picture`)
		if (response.status === 200) {
			return response.blob()
		} else {
			const text = await response.text()
			return await Promise.reject(text)
		}
	}
}

import {UserResponse} from '../../Types/User'
import {BaseController} from './BaseController'

export interface Person {
  affiliation: string
  department?: string
  display_name: string
  email?: string
  first_name: string
  initials?: string
  kerberos: string
  kerberos_id: string
  krb_name: string
  last_name: string
  mitHROrganisationId?: string
  mit_id: string
  mitid: string
  office_location?: string
  phone_number?: string
  source: string
}

export class AccountController extends BaseController {
  readonly getUsersUrl: string
  readonly getDepartmentsUrl: string

  constructor() {
    super()
    this.getUsersUrl = `${this.apiHost}/${this.apiPathDigitalId}/search?q=`
    this.getDepartmentsUrl = `${this.apiHost}/${this.apiPathAccount}/admin/departments`
  }

  async getUsers(search: string): Promise<Person[]> {
    const response = await this.useFetch('GET', 'getUsers', `${this.getUsersUrl}${search}`)
    if (this.checkStatusCode(response.status)) {
      return response.json()
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async getDepartments(_search: string): Promise<UserResponse> {
    const response = await this.useFetch('GET', 'getDepartments', `${this.getDepartmentsUrl}`)

    if (this.checkStatusCode(response.status)) {
      return response.json()
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }
}

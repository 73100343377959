import {Countries} from 'Types/Countries'
import {Country} from 'Types/Country'
import {Departments} from 'Types/Departments'
import {Program} from 'Types/Program'
import {Programs} from 'Types/Programs'
import {ProgramStats} from 'Types/ProgramStats'
import {Roles} from 'Types/Roles'
import {Travelers} from 'Types/Travelers'
import {TripDetails} from 'Types/TripDetails'
import {BaseController} from './BaseController'

interface GetResponse {
  data: Programs,
  status: number
}

export type View = 'current' | 'past' | 'rejected'
export class TravelController extends BaseController {

  //Roles
  async getRoles (): Promise<Roles> {
    const response = await this.useFetch('GET', 'get/roles', `${this.apiHost}/${this.apiPathTravel}/roles`)

    if (this.checkStatusCode(response.status)) {
      return await response.json()
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async getPrograms (status: View = 'current'): Promise<GetResponse> {
    const response = await this.useFetch('GET', 'getPrograms', `${this.apiHost}/${this.apiPathTravel}/programs?status=${status}`)

    if (this.checkStatusCode(response.status)) {
      const data = await response.json()
      return {
        data: data,
        status: response.status
      }
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async getProgramStats (id: string): Promise<ProgramStats> {
    const response = await this.useFetch('GET', 'getProgramStats', `${this.apiHost}/${this.apiPathTravel}/programs/${id}/stats`)

    if (this.checkStatusCode(response.status)) {
      return response.json()
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async addProgram (body: Program): Promise<Program> {
    const response = await this.useFetch('POST', 'addProgram', `${this.apiHost}/${this.apiPathTravel}/programs`, body)

    if (this.checkStatusCode(response.status)) {
      return await response.json()
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async addProgramTravelers (id: string,body: Travelers): Promise<any> {
    const response = await this.useFetch('POST', 'addProgramTravelers', `${this.apiHost}/${this.apiPathTravel}/programs/${id}/travelers`, body)

    if (this.checkStatusCode(response.status)) {
      return true
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async updateProgram (body: Program): Promise<any> {
    const response = await this.useFetch('PUT', 'updateProgram', `${this.apiHost}/${this.apiPathTravel}/programs`, body)

    if (this.checkStatusCode(response.status)) {
      return true
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async removeProgram (id: string): Promise<any> {
    const response = await this.useFetch('DELETE', 'removeProgram', `${this.apiHost}/${this.apiPathTravel}/programs/${id}`)

    if (this.checkStatusCode(response.status)) {
      return true
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async removeTrip (tripId: string): Promise<any> {
    const response = await this.useFetch('DELETE', 'removeTrip', `${this.apiHost}/${this.apiPathTravel}/trips/${tripId}`)

    if (this.checkStatusCode(response.status)) {
      return true
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async getCountries (): Promise<Countries> {
    const response = await this.useFetch('GET', 'getCountries', `${this.apiHost}/${this.apiPathTravel}/countries`)

    if (this.checkStatusCode(response.status)) {
      return response.json()
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async getCountry (countryCode: string): Promise<Country> {
    const response = await this.useFetchNoAbort('GET', `${this.apiHost}/${this.apiPathTravel}/countries/${countryCode}`)

    if (this.checkStatusCode(response.status)) {
      const countries = (await response.json()) as Countries

      return countries.countries[0]
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async updateCountries (body: Country): Promise<any> {
    const response = await this.useFetch('PUT', 'putCountries', `${this.apiHost}/${this.apiPathTravel}/countries`, body)

    if (this.checkStatusCode(response.status)) {
      return true
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async getDepartments (): Promise<Departments> {
    const response = await this.useFetch('GET', 'getDepartments', `${this.apiHost}/${this.apiPathTravel}/departments`)

    if (this.checkStatusCode(response.status)) {
      return response.json()
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async getTravelers (department: string): Promise<Travelers> {
    const response = await this.useFetch('GET', 'getTravelers', `${this.apiHost}/${this.apiPathTravel}/travelers?department=${department}`)

    if (this.checkStatusCode(response.status)) {
      return response.json()
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async getTravelersViaMitID (mitId: string): Promise<Travelers> {
    const response = await this.useFetch('GET', 'getTravelers', `${this.apiHost}/${this.apiPathTravel}/travelers?mitId=${mitId}`)

    if (this.checkStatusCode(response.status)) {
      return response.json()
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async getTravelersViaKerbName (krbName: string): Promise<Travelers> {
    const response = await this.useFetch('GET', 'getTravelers', `${this.apiHost}/${this.apiPathTravel}/travelers?krbName=${krbName}`)

    if (this.checkStatusCode(response.status)) {
      return response.json()
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async getTripTravelerDetails (id: string, status: View = 'current'): Promise<TripDetails> {
    const response = await this.useFetch('GET', 'getTripTravelerDetails', `${this.apiHost}/${this.apiPathTravel}/trip-details?travelerId=${id}&status=${status}`)

    if (this.checkStatusCode(response.status)) {
      return response.json()
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async getTripProgramDetails (id: string, status: View = 'current'): Promise<TripDetails> {
    const response = await this.useFetch('GET', 'getTripProgramDetails', `${this.apiHost}/${this.apiPathTravel}/trip-details?programId=${id}&status=${status}`)

    if (this.checkStatusCode(response.status)) {
      return response.json()
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async getTripDetails(id: string): Promise<TripDetails> {
    const response = await this.useFetch('GET', 'getTripProgramDetails', `${this.apiHost}/${this.apiPathTravel}/trip-details?programId=${id}`)

    if (this.checkStatusCode(response.status)) {
      return response.json()
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async getTripDetailsViaTripId(id: string): Promise<TripDetails> {
    const response = await this.useFetch('GET', 'getTripProgramDetails', `${this.apiHost}/${this.apiPathTravel}/trip-details?tripId=${id}`)

    if (this.checkStatusCode(response.status)) {
      return response.json()
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

  async getTravelerDetails(id: string): Promise<any> {
    const response = await this.useFetch('GET', 'getTravelerDetails', `${this.apiHost}/${this.apiPathTravel}/travelers/${id}`)

    if (this.checkStatusCode(response.status)) {
      return response.json()
    } else {
      const text = await response.json()
      return await Promise.reject(text.message)
    }
  }

}

import {ILoaderModel, LoaderModel} from './Loader'
import {IProfileModel, ProfileModel} from './Profile'

export interface ITemplateModel {
  LoaderModel: ILoaderModel
  ProfileModel: IProfileModel
}

export const TemplateModel: ITemplateModel = {
  LoaderModel,
  ProfileModel
}

import React, { FC } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { StoreProvider } from 'easy-peasy';
import { store } from 'Store';
import reportWebVitals from './reportWebVitals';
import 'Scss/main.scss';
import { useAppInitializer } from './TemplateConstants/authorization/Initialization'
import { Initialization } from '@mit/aws-react';
import appConfig from './app-config-global';
import { Logout } from 'TemplateConstants/components/Logout';
import '@fortawesome/fontawesome-pro/css/all.min.css'

const WrappedApp: FC = () => {
  const { initializeApp } = useAppInitializer()

  const AppLazyLoad = React.lazy(async () => await import('Components/app/App').then(module => ({
    default: module.default
  })))

  return (
    <StoreProvider store={store}>
      <Router>
        <Switch>
          <Route exact path='/logout' component={Logout} />
          <Route path='/'>
            <Initialization
              App={AppLazyLoad}
              appInitializer={initializeApp}
              appConfigData={appConfig}
              loaderProps={{
                contactEmail: 'travel-admin@mit.edu',
                name: 'Travel Admin',
                isLoading: true,
                type: 'No Access',
              }}
            />
          </Route>
        </Switch>
      </Router>
    </StoreProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <WrappedApp />,
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();